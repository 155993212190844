import almond_milk from '../assets/almond_milk.jpg';
import americano from '../assets/americano.jpg';
import apple_juice from '../assets/apple_juice.jpg';
import apple from '../assets/apple.jpg';
import avocado from '../assets/avocado.jpg';
import banana from '../assets/banana.jpg';
import banner from '../assets/Banner.jpg';
import beef from '../assets/beef.jpg';
import beer from '../assets/beer.jpg';
import biscuit from '../assets/biscuit.jpg';
import black_tea_green_tea from '../assets/black_tea_green_tea.jpg';
import bread from '../assets/bread.jpg';
import brown_bread from '../assets/brown_bread.jpg';
import cappuccino from '../assets/cappuccino.jpg';
import chicken from '../assets/chicken.jpg';
import chocolate_cake from '../assets/chocolate_cake.jpg';
import chocolate_snack_bar from '../assets/chocolate_snack_bar.jpg';
import crab from '../assets/crab.jpg';
import doughnut from '../assets/doughnut.jpg';
import egg from '../assets/egg.jpg';
import ice_cream from '../assets/ice_cream.jpg';
import kiwi from '../assets/kiwi.jpg';
import latte from '../assets/latte.jpg';
import lobster from '../assets/lobster.jpg';
import mango from '../assets/mango.jpg';
import marshmallow from '../assets/marshmallow.jpg';
import milk_based_sweet from '../assets/milk_based_sweet.jpg';
import milk from '../assets/milk.jpg';
import mozzarella from '../assets/mozzarella.jpg';
import oatmilk from '../assets/oatmilk.jpg';
import oyster from '../assets/oyster.jpg';
import pork from '../assets/pork.jpg';
import salmon from '../assets/salmon.jpg';
import shrimp from '../assets/shrimp.jpg';
import soft_drink from '../assets/soft_drink.jpg';
import soy_milk from '../assets/soy_milk.jpg';
import sponge_cake from '../assets/sponge_cake.jpg';
import strawberry from '../assets/strawberry.jpg';
import logo from '../assets/logo.png'
import member_hoanh_anh from '../assets/member_hoanh_anh.jpg';
import member_hoanh_oanh from '../assets/member_hoanh_oanh.jpg';
import member_huu_thang from '../assets/member_huu_thang.jpg';
import member_ngoc_nhung from '../assets/member_ngoc_nhung.jpg';
import member_khach_dat from '../assets/member_khach_dat.JPG';
import tus_goverment from '../assets/The_United _States_Government.png';
import for_good_vietnam from '../assets/ForGood_Vietnam.png';
import green_move from '../assets/Green_Move.png';
import american_center_hcmc from '../assets/American_Center_HCMC.png'
export const images = {
  almond_milk,
  americano,
  apple_juice,
  apple,
  avocado,
  banana,
  beef,
  beer,
  biscuit,
  black_tea_green_tea,
  bread,
  brown_bread,
  cappuccino,
  chicken,
  chocolate_cake,
  chocolate_snack_bar,
  crab,
  doughnut,
  egg,
  ice_cream,
  kiwi,
  latte,
  lobster,
  logo,
  mango,
  marshmallow,
  milk_based_sweet,
  milk,
  mozzarella,
  oatmilk,
  oyster,
  pork,
  salmon,
  shrimp,
  soft_drink,
  soy_milk,
  sponge_cake,
  strawberry,
  banner,
  member_hoanh_anh,
  member_hoanh_oanh,
  member_huu_thang,
  member_khach_dat,
  member_ngoc_nhung,
  tus_goverment,
  american_center_hcmc,
  for_good_vietnam,
  green_move

}













