export const colors = {
    primary: '#A13B46',
    second: '#DAAA5D',
    white: '#FFFFFF',
    black: '#000000'
}


// DAAA5D
// A13B46
// Trắng
// Đen
